//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import apiMaterials from "@/api/apiMaterials";
var env = require("../../../../env");
export default {
  name: "Materials",
  props: {
    dataList1: {
      type:Array
    }
  },
  data() {
    return {
      filterLength: "23",
      dataList:this.dataList1,
      oneList: this.$store.state.listMune
    };
  },
  created() {
    // this.getData()
  },
  mounted() {
    // this.getNewData();
  },
  methods: {
    setShopList() {
      if (process.client) {
        for (let i = 0; i < this.dataList.length; i++) {
          // 遍历所有菜单 一级
          // showType 0 图文 1 列表 2 商砼
          this.dataList[i].showType = 0;
          this.dataList[i].cidName1 = this.dataList[i].categoryNames;
          this.dataList[i].cid1 = this.dataList[i].categorys.split(",");

          let index1 = this.oneList.indexOf(this.dataList[i].categoryNames);
          if (index1 !== -1) {
            // 是列表属性 返回
            this.dataList[i].showType = 1;
          }

          if (this.dataList[i].categoryNames == "商砼") {
            // 建立商砼id list
            this.dataList[i].showType = 2;
          }

          if (Array.isArray(this.dataList[i].list) == false) {
            // 判断是不是数组
            continue;
          }
          for (let j = 0; j < this.dataList[i].list.length; j++) {
            // 遍历二级
            if (this.dataList[i].list[j].parentCategoryName == "商砼") {
              this.dataList[i].list[j].showType = 2;
            } else {
              this.dataList[i].list[j].showType = this.dataList[i].showType;
            }
            this.dataList[i].list[j].cidName1 = this.dataList[i].categoryNames;
            this.dataList[i].list[j].cidName2 = this.dataList[i].list[
              j
            ].categoryName;

            this.dataList[i].list[j].cid1 = this.dataList[i].categorys.split(
              ","
            );
            this.dataList[i].list[j].cid2 = this.dataList[i].list[j].category;

            if (
              Array.isArray(this.dataList[i].list[j].childCategory) == false
            ) {
              // 判断是不是数组
              continue;
            }
            for (
              let n = 0;
              n < this.dataList[i].list[j].childCategory.length;
              n++
            ) {
              this.dataList[i].list[j].childCategory[
                n
              ].showType = this.dataList[i].list[j].showType;
              this.dataList[i].list[j].childCategory[
                n
              ].cidName1 = this.dataList[i].list[j].cidName1;
              this.dataList[i].list[j].childCategory[
                n
              ].cidName2 = this.dataList[i].list[j].cidName2;
              this.dataList[i].list[j].childCategory[
                n
              ].cidName3 = this.dataList[i].list[j].childCategory[
                n
              ].categoryName;

              this.dataList[i].list[j].childCategory[n].cid1 = this.dataList[
                i
              ].list[j].cid1;
              this.dataList[i].list[j].childCategory[n].cid2 = this.dataList[
                i
              ].list[j].cid2;
              this.dataList[i].list[j].childCategory[n].cid3 = this.dataList[
                i
              ].list[j].childCategory[n].category;
            }
          }
        }
      }
    },
    selectFirstItem(obj, parentId, idx, event) {
      if (process.client) {
        let cName = event.currentTarget.innerText.replace(" ", "");
        let parentIds = parentId.split(",");
        if (parentIds.length > 1) {
          let childLists = obj.list.filter(
            item => item.parentCategory == parentIds[idx]
          );
          if (cName == "商砼") {
            childLists[0].cidName1 = cName;
            childLists[0].showType = 2;
          }
          this.toView(childLists[0]);
        } else {
          this.toView(obj.list[0]);
        }
      }
    },
    getObj(item) {
      // console.log(item);
      let obj = {
        // cid1:undefined,
        // cid2:undefined,
        // cid3:undefined,
        cidName1: undefined,
        cidName2: undefined,
        cidName3: undefined,
        showType: undefined
      };
      for (let value in obj) {
        obj[value] = item[value];
      }
      // console.log(obj);
      if (obj.cidName2 == undefined) {
        if (Array.isArray(item.list) == true && item.list.length > 0) {
          // 判断是不是数组
          obj.cidName2 = item.list[0].cidName2;
          // obj.cid2 = item.childCategory[0].cid2
        }
        if (
          Array.isArray(item.list[0].childCategory) == true &&
          item.list[0].childCategory.length > 0
        ) {
          // 判断是不是数组
          obj.cidName3 = item.list[0].childCategory[0].cidName3;
          // obj.cid3 = item.childCategory[0].childCategory[0].cid3
        }
        return obj;
      }

      if (obj.cidName3 == undefined) {
        if (
          Array.isArray(item.childCategory) == true &&
          item.childCategory.length > 0
        ) {
          // 判断是不是数组
          obj.cidName3 = item.childCategory[0].cidName3;
          // obj.cid3 = item.childCategory[0].cid3
        }
        return obj;
      }
      return obj;
    },
    toView(item) {
      if (process.client) {
        // showType 0 图文 1 列表 2 商砼
        let obj = this.getObj(item);
        const URL = env[process.env.environment].VUE_APP_MATERIALSURL;
        var i,
          str = "";
        for (i in obj) str += "&" + i + "=" + obj[i];
        // let url = str.replace(/&/, "?");
        let reg = new RegExp("&")
        let url = str.replace(reg, "?");
        switch (obj.showType) {
          case 0:
            window.open(`${URL}/engineeringView${url}`);
            // this.$router.push({
            //   path: `${URL}/engineeringView`,
            //   query: obj
            // })
            return;
          case 1:
            window.open(`${URL}/EngineeringList${url}`);
            // this.$router.push({
            //   path: `${URL}/EngineeringList`,
            //   query: obj
            // })
            return;
          case 2:
            window.open(`${URL}/talents`);
            // this.$router.push(`${URL}/talents`)
            return;
        }
      }
    },
    async getData() {
      let res = await apiMaterials.getClassify();
      if (res.data.code == 200) {
        this.dataList = res.data.data;
        this.setShopList();
      }
    },
    async getNewData() {
      let res = await apiMaterials.getListByAreaCode();
      if (res.data.code == 0) {
        this.dataList = res.data.data;
      }
    },
    //点击跳转.
    jumpUrl(item) {
      if (process.client) {
        const URL = env[process.env.environment].VUE_APP_MATERIALSURL;
        let cidName1 = item.name;
        let cidName2 = '';
        if(item.children && item.children[0] && item.children[0].name) {
          cidName2 = item.children[0].name;
        }
        let cidName3 = '';
        if(item.children && item.children[0] && item.children[0].children && item.children[0].children[0]) {
          cidName3 = item.children[0].children[0].name;
        }
        if (item.name === "商砼") {
          window.open(`${URL}/talents`);
        /*} else if (item.showType === 0) {
          window.open(
            `${URL}/engineeringView?cidName1=${cidName1}&cidName2=${cidName2}&cidName3=${cidName3}&showType=${item.showType}`
          );*/
        } else {
          window.open(
            `${URL}/EngineeringList?c1=${item.id}`
          );
        }
      }
    },
    //点击'商品需求'
    clickCommodity() {
      if (process.client) {
        const URL = env[process.env.environment].VUE_APP_MATERIALSURL;
        window.open(`${URL}/#/GoodsDemand?name=&city=&areaCode=650000`);
      }
    },
    //点击'发现供应商'
    clickSupplier() {
      if (process.client) {
        const URL = env[process.env.environment].VUE_APP_MATERIALSURL;
        window.open(`${URL}/#/supplier?name=&areaCode=650000&city=`);
      }
    }
  }
};
