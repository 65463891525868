//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import stickybits from "stickybits";
import { apiHome } from "@/api/apiHome";
import { apiInquiryPrice } from "@/api/apiInquiryPrice";
import { Throttle } from '@/util/util.js';
import env from "@/../env.js";
function debounce(fn, wait = 500, isImmediate = false) {
  let timerId = null;
  let flag = true;
  if (isImmediate) {
    return function () {
      clearTimeout(timerId);
      if (flag) {
        fn.apply(this, arguments);
        flag = false;
      }
      timerId = setTimeout(() => {
        flag = true;
      }, wait);
    };
  }
  return function () {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      fn.apply(this, arguments);
    }, wait);
  };
}

export default {
  name: "category",
  data() {
    return {
      selectCategory: {},
      serverData: [],
      isShow: true,
      goodsOptions: {},
      loading: false,
      isShowDetail: 0
    };
  },
  props: {
  },
  computed: {
    categoryList: function () {
      let serverData = JSON.parse(JSON.stringify(this.serverData));
      return serverData;
    }
  },
  async created() {
    await this.homePageSecondTypeConfig()
    // await this.getCategory();
    // this.getGoodsType();
  },
  mounted() {
    this.$nextTick(() => {
      this.getCategory();
    })
  },
  methods: {
    // 获取类目配置数据
    async getCategory() {
      let res = await apiHome.getCategoryData();
      let { code, data, msg } = res.data;
      if (code == 200) {
        this.serverData = data;
      }
    },
    // 商品分类数据
    async getGoodsType() {
      let res = await apiInquiryPrice.getProducts();
      let { code, data, msg } = res;
      if (code == 200) {
        data = data.filter(item => {
          if (item && item.children && item.children.length > 0) {
            return item;
          }
        });
        this.goodsOptions = this.handleGoodsData(data);
      }
    },
    // 商品分类数据处理
    handleGoodsData(data, disabled = false, ids = []) {
      return data.map(item => {
        if (item.children && item.children.length > 0) {
          item.children = this.handleGoodsData(item.children);
        }
        return {
          ...item,
          disabled: disabled && !ids.includes(item.id),
          value: item.na
        };
      });
    },
    // 拆分组合分类名称
    getCategoryNames(names) {
      names = names || "";
      names = names.split(",");
      return names;
    },
    // 查看更多分类
    tomore() {
      window.open(
        `${env[process.env.environment].VUE_APP_MATERIALSURL}/engineeringList`
      );
    },
    // 点击一级类目
    clickLeveOne(listItem) {
      window.open(
        `${env[process.env.environment].VUE_APP_MATERIALSURL
        }/engineeringList?c1=${listItem.id}`
      );
    },
    // 点击二级类目
    clickLeveTwo(listItem, item2) {
      window.open(
        `${env[process.env.environment].VUE_APP_MATERIALSURL
        }/engineeringList?c1=${listItem.id}&c2=${item2.id}`
      );
    },
    // 点击三级类目
    clickLeveThree(listItem, item2, item3) {
      window.open(
        `${env[process.env.environment].VUE_APP_MATERIALSURL
        }/engineeringList?c1=${listItem.id}&c2=${item2.id}&c3=${item3.id}`
      );
    },
    // 跳转到店铺
    goToCompany(item) {
      window.open(
        `${env[process.env.environment].VUE_APP_MATERIALSURL}/company/${item.supplier
        }?no=${item.no}`
      );
    },
    // 获取一级下类目
    async homePageSecondTypeConfig(params, value) {
      this.loading = true
      try {
        let res = await apiHome.homePageSecondTypeConfig(params)
        let { code, data, msg } = res.data;
        this.$set(value, 'itemTypeView', data.itemTypeView)
        this.selectCategory = value
        this.loading = false
      } catch (error) {
        this.loading = false
      }
      // console.log('list', this.serverData, datalist)
    },
    hoverHandler: debounce(
      function (value) {
        // console.log('this.isShowDetail ', this.isShowDetail )
        // this.isShowDetail == 0
        if (this.isShowDetail == 1) {
          this.isShowDetail = 2
          return
        }
        if (!value.itemTypeView || !value.itemTypeView.length) {
          let id = value.id
          this.homePageSecondTypeConfig({ id }, value)
        } else {
          this.selectCategory = value
        }
      },
      300,
      false
    ),
    leaveHandler(e) {
      this.selectCategory = {}
    },
  }
};
